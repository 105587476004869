import * as React from "react"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const NotFoundPage = () => {
  const { t } = useTranslation()

  return (
    <section className="container px-10 lg:mb-96 mx-auto flex flex-col justify-center items-center ">
      <article className="w-full mx-auto py-6 px-2 flex flex-col justify-center items-center h-screen">
        <StaticImage src="../images/404.png" className="w-40" alt="Error 404" />
        <p>{t("error.titulo")}</p>
        <h1>{t("error.texto")}</h1>
        <Link
          to={t("rutas.home")}
          className="px-8 py-2 rounded-xl bg-negro border border-gris text-gris hover:scale-105 transition duration-100 mt-5"
        >
          {t("error.boton")}
        </Link>
      </article>
    </section>
  )
}

export default NotFoundPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
